<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <Footer slot="footer" />

        <div slot="content" style="text-align:left">

            <div class="container-fluid" style="margin-top:10px; position:relative; max-width:100%">

                <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div> -->

                <template v-if="searchString == ''">
                    <template v-if="productDetailComponent">
                        <productDetail />
                    </template>

                    <!-- Mobile View -->
                    <div v-if="windowWidth < 501"  style="background:#fff; padding:10px;border-radius:20px " >
                        <div class="mb-2 mt-2">
                            <span v-if="currentCategory == 'primary'"  class="head-font">
                        <b> 
                        <img src="../../assets/images/chev.png" alt="" height="18" @click="gotoMarket" class="pr-4 pb-1">
                                {{tran.primary}} 
                               </b>
                            </span>

                            <span v-if="currentCategory == 'secondary'" class="head-font"><b><img src="../../assets/images/chev.png" alt="" height="18" @click="gotoMarket" class="pr-4 pb-1  head-font"> {{tran.secondary}} </b></span>
                            <span v-if="currentCategory == 'soldOut'" class="head-font"><b><img src="../../assets/images/chev.png" alt="" height="18" @click="gotoMarket" class="pr-4 pb-1 head-font"> {{tran.sold_out_paid}} </b></span>

                          
                            <!-- <hr style="margin:0rem" class="mt-2 mb-2"> -->
                        </div>

                        <div class="mobile-product-div" >
                            <template v-for="(prod, index) in categoryArray">
                                <div class="mb-2" :key="index">
                                    <div class="product-img-div" :style="{ backgroundImage: 'url(' + serVerImage+prod.product_image + ')' }" @click.prevent="showMobileComponentWithUrl(prod, 'upcoming') " v-if="prod.category== 'upcoming'">
                                    </div>
                                    <div class="product-img-div" :style="{ backgroundImage: 'url(' + serVerImage+prod.product_image + ')' }" @click.prevent="showMobileComponentWithUrl(prod, 'notupcoming') " v-else>
                                    </div>

                                    <div class="pt-1">
                                        <h5>{{prod.name}}</h5>
                                        <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' && prod.category !== 'upcoming' || prod.resell_units_available > 0 && prod.settlement_status !== 'settled' && prod.category !== 'upcoming'">

                                            <h6> <b> {{tran.up_to}} {{prod.roi}}% </b> </h6>
                                             <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                        
                                        </template>

                                        <template v-if="prod.category == 'upcoming'">

                                           <span class="paid-out-mob" style="font-size:12px">COMING SOON</span>
                                        </template>

                                      
                                           <template v-if="prod.units_available < 1 && prod.settlement_status !== 'settled' || prod.resell_units_available < 1 && prod.settlement_status !== 'settled'">
                                        <span class="sold-out-mob" style="font-size:12px">SOLD OUT</span>

                                    </template>
                                        <template v-if="prod.units_available < 1 && prod.name === 'Rice Farm 02'">
                                        <span class="phased-out-mob">PHASED</span>

                                    </template>

                                    <template v-if="prod.settlement_status == 'settled' && prod.name !== 'Rice Farm 02'" >
                                        <span class="paid-out-mob" style="font-size:12px">PAID OUT</span>

                                    </template>
                                    </div>
                                </div>
                            </template>
                        </div>

                    </div>

                    <!-- Desktop View -->
                    <div v-show="windowWidth > 500"  class="product-cover mt-4">
                        <div class="display-div pb-2 ">
                            <div>
                                <span style="cursor:pointer" @click="gotoMarket" class="pr-1 mkt-link"><b>{{tran.market}}</b></span>  <span CLASS="pr-1"> <i class="fa fa-angle-double-right"></i>  </span>
                                <span v-if="currentCategory == 'primary'">{{tran.primary}} </span>
                                <span v-if="currentCategory == 'soldOut'">{{tran.sold_out_paid}} </span>
                                <span v-if="currentCategory == 'secondary'">{{tran.secondary}} </span>
                                <span v-if="currentCategory == 'resell'">{{tran.up_for_sale}} </span> 
                            </div>
                        </div>

                        <div v-for="(prod, index) in categoryArray" :key="index" style="display:inline; border-radius:10px" :id="'product' + prod.id">
                            <div class="item" @click="getProductObject(prod, $event); scrollToProduct (prod)">
                                <div class="img-div-1" :class="{'showHideDiv': productSelected == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                                </div>
                            <div class="img-div" :style="{ backgroundImage: 'url(' +serVerImage+prod.product_image + ')' }">
                                    <p class="scrollDiv"></p>

                                
                                    <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' || prod.resell_units_available > 0 && prod.settlement_status !== 'settled'">

                                        <p class="text-right" style="text-decoration:underline" v-if="currentCategory !== 'resell' && prod.units_available !== undefined">{{formatAmount(prod.units_available)}} {{tran.units_available}}</p>
                                        <p class="text-right" style="text-decoration:underline" v-else>{{prod.resell_units_available || formatAmount(prod.units_available) }} {{tran.units_available}}</p>

                                    </template>

                                      <template v-if="prod.units_available < 1 && prod.settlement_status !== 'settled' || prod.resell_units_available < 1 && prod.settlement_status !== 'settled'">
                                                                <div class="sold-out">
                                                                    <p>SOLD OUT</p>
                                                                </div>
                                                            </template>
                                                              <template v-if="prod.units_available < 1 && prod.name === 'Rice Farm 02'">
                                        
  <div class="phased-out">
                                                                    <p>PHASED</p>
                                                                </div>
                                    </template>
                                    <template v-if="prod.settlement_status == 'settled' && prod.name !== 'Rice Farm 02'">
                                                                <div class="paid-out"  >
                                                                    <p>PAID OUT</p>
                                                                </div>
                                                            </template>
                                    <div class="atp-preview">
                                        <div>
                                            <h6>
                                                <b>{{prod.name}}</b>
                                            </h6>
                                            <p>{{prod.atp}}</p>
                                        </div>
                                        <!-- <div class="atp-roi">
                                            <p v-if="currentCategory == 'primary' || currentCategory == 'upcoming'">{{tran.up_to}} {{prod.roi}}%</p>
                                            <p v-else-if="currentCategory == 'secondary'"> {{prod.roi}}%</p>
                                            <p v-else>{{tran.up_to}} {{prod.resell_roi}}%</p>
                                            <p>{{prod.matures_at}}</p>
                                        </div> -->

                                            <div class="atp-roi">
                                                    <p>  <span v-if="currentCategory == 'primary' || currentCategory == 'upcoming'">{{tran.up_to}}</span><span v-if="prod.resell_units_available < 1 || prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    
                                                  
                                                <p v-if="prod.resell_units_available < 1  || prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else>
                                                              <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                                        
                                                      </p>
                                                </div>
                                          </div>
                                     </div>

                                <div style="position:relative;text-align:center; right:12px">

                                    <img src="../../assets/images/chev-up.png"  class="fa-angle-down animated slideInDown mb-4"  :class="{'show-elem-inline' : prod.id == productSelectedByUser}" style="margin-top:0.8rem!important" height="30">
                                        </div>
                                </div>








                                <div class="info mb-3" :class="{'show-elem' : prod.id == productSelectedByUser && prod.category == selectedCategory}">
                                  
                                    
                                            <div class="investment-details">
                                     

                                                <div class="product-detail mr-4">
                                                    <div>
                                                        <h3>{{prod.name}} <span v-if="prod.category == 'upcoming'">(Coming Soon)</span></h3>
                                                     
                                                        <div v-html="prod.details"></div>

                                                    </div>
                                                   
                                                </div>

                                                <div class="cart-point mt-4">
                                                    <div style="" class="close-btn">
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4 point-cursor" @click="sendUserToAtpDetailView(prod.atp_id)">
                                                      <img :src="imgUrlFromServer+prod.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{prod.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{prod.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                        <img src="../../assets/images/close-exit.png" loading="lazy" height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                         <div class="crt-div mt-4 mb-4">
                                                     <div style="display:flex;" >
                                                         <img src="../../assets/images/ui1.png" loading="lazy" height="15" class="mr-2">
                                                         <p v-if="prod.units_available < 1" class="user-roi  fw-600"> {{formatAmount(prod.roi_opened_with) || formatAmount(prod.roi) }} % Interest</p>
                                          <p class="user-roi fw-600" v-else> {{formatAmount(prod.roi)}}% Interest</p>

                                               <div style="display: flex;
    justify-content: flex-end;">


       <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.urlName}`+'/'+`${prod.id}`" style="position:absolute">
                                                      
                                                        <img src="../../assets/images/share-new.png" loading="lazy" height="30" style="cursor:pointer" @click.prevent="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                                </div>
                                         
                                                    </div>

                                             <div style="display:flex;">
                                               <p class="text-left fw-600" v-if="prod.units_available < 1">
                                                    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.product_opened_with}}</p>
                                                                <p class="text-left fw-600" v-else>    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">
                                                                  <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span>
                                                         </p>
                                                 
<!-- 
                                              <p class="text-right fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}}</p> -->
                                                            </div>

                                          
                                                <div style="display:flex;">
                                                     <img src="../../assets/images/ui3.png" loading="lazy" height="15" class="mr-2">
                                                <p class="text-left fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}} per unit</p> 

                                            <!-- <p class="text-right fw-600" v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                                <p class="text-right fw-600" v-else>{{prod.matures_at}}</p> -->

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Opens On:</span>
                                                                <b> {{prod.opens_at | formatDate }}</b>
                                                                </p>

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Matures On:</span>
                                                                <b> {{prod.matures_at | formatDate }}</b>
                                                                </p>
                                                              

                                                            </div>
                                                        </div>

                                                        <div class="add-units" :class="{'fade-elem': prod.units_available == 0, 'hide-elem':prod.category == 'upcoming' ||currentCategory == 'soldOut' }">
                                                                 <div class="display-div pt-3" style=" padding:6px 15px; border-bottom: 1px solid #F2F2F2;">
                                                                <div class="text-left" >
                                                            <p class="pb-1" style="color:#828282
">Cost (Multiples of ₦5,000):</p>
                                                                   <span style="color:#0094FF">&#8358;</span>
                                                                    <span class="cost-unit text-center" style="color:#0094FF">   {{formatAmount(prod.user_cost)}}</span>

                                                                </div>
                                                                <div class="text-left" style="color:#58B46F">
                                                                        <p class="pb-1" style="color:#828282
">ROI (Return on Investment):</p>
                                                                    <span>&#8358;</span>
                                                                    <span class="user-amount text-center">{{formatAmount(prod.user_roi)}}</span>

                                                                </div>

                                                            </div>
                                                            <h6 class="pt-4 pb-2 text-left"><b>Add Units</b> <span style="color:#828282
">(One unit =  ₦5,000)</span></h6>

    <div style="padding:10px; display:flex; justify-content:space-between">

                                                            <div style="padding:0px 15px;display:flex; align-items:center; border: 0.5px solid #33A9FF; border-radius:5px; background:#F5F5F5; flex-basis:60%" class="mr-2" >
                                                        
                                                                <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }" style="flex-basis:10%"></i>

                                                                <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected" style="flex-basis:80%; background:transparent">

                                                                <i class="fa fa-plus text-right" @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.units_available }" style="flex-basis:10%"></i>

                                                            </div>
                                                       <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader }" v-if="token !== null" style="flex-basis:30%">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                            <button class="cart-btn"  @click="sendGuestTosignUp" style="flex-basis:30%" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>    
    </div>
                                                       
                                                         
                                                        </div>
                                                    </div>
                                                </div>

                                    </div>
                                </div>
                                <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                            </div>
                </template>
                <template v-if="searchString !== ''">
                    <searchProduct />
                </template>
            </div>
        </div>
    </Wrapper>
          
</div>
</template>

<script>
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";

import Wrapper from "../partials/Wrapper";
import productDetail from "../partials/MobileProductDetail";
import searchProduct from "./SearchProduct";

import {
    mapState
} from "vuex";

import {
    commonJs
} from "./mixins/commonJs.js";

export default {
    mixins: [commonJs],
    data() {
        return {
            slickOptions: {
                slidesToShow: 3,
                slidesToScroll: 1,
                // autoplay: true,
                // autoplaySpeed: 2000,
                // dots: true,
                variableWidth: true
            },
            productSelected: this.$route.params.id,
            units: "",
            cartInput: 0,
            prodAdded: false,
            search: "",
            startDate: "",
            endDate: "",
            minInterest: "",
            maxInterest: "",
            selectedCategory: null
        };
    },
    methods: {

        filteredData(data) {
            this.$helpers.log(this.minInterest);
            let vm = this;
            return _.filter(data, function (data) {
                if (
                    (vm.startDate == "" || vm.endDate == "") &&
                    (vm.minInterest == "" || vm.maxInterest == "")
                ) {
                    return true;
                } else if (vm.startDate && vm.endDate) {
                    let date = data.tenor;
                    return date >= vm.startDate && date <= vm.endDate;
                } else if (vm.minInterest && vm.maxInterest) {
                    let interestInLoop = data.interest;
                    return (
                        interestInLoop >= vm.minInterest && interestInLoop <= vm.maxInterest
                    );
                }
            });
        },

        scrollToProduct(prod) {
            this.$helpers.log(prod.id, 'id');
            this.$router.push({
                params: {
                    id: prod.id
                },

                path: `/market/${ prod.category }/${prod.id}`,
            })

        },

        scrollToDiv() {
            let first = this.$route.fullPath.split("/");
            if (first[3] && this.windowWidth > 701)
                document.getElementById('product' + first[3].split("#")[0]).scrollIntoView();

        }
    },
    watch: {
        scrollStatus() {

            if (this.scrollStatus == true) {

                this.scrollToDiv();

            }
        }

    },
    computed: {
        ...mapState({
            categoryArray: state => state.market.categoryArray,
            cartItems: state => state.market.cartItems,
            scrollStatus: state => state.market.scrollStatus,
            productsForMobile: state => state.market.productsForMobile,
            currentCategory: state => state.market.currentCategory,
            productObject: state => state.market.productObject,
            productsForSale: state => state.market.productsForSale,
            productDetailComponent: state => state.market.productDetailComponent
        }),
        productSelectedByUser() {
            return this.$route.params.id
        },
    },
    components: {
        Navbar,
        Footer,
        productDetail,
        Sidebar,
        Wrapper,
        searchProduct,
    },

    mounted() {
        this.$store.dispatch("market/getAllProducts");
        this.$store.dispatch("market/getCategoryData");

        let heading = {

            main: this.tran.market,
            sub: this.tran.all_market
        }

        this.$store.commit('market/savePageHeading', heading)

        let first = this.$route.fullPath.split("/");
        this.selectedCategory = first[2]

        if (first[2] && this.windowWidth < 501 && this.searchString == '') {

            let categoryData = localStorage.getItem("categoryData");

            categoryData = JSON.parse(categoryData);

            let prod = categoryData.filter((prod) => {
                if (prod.id == first[3] && prod.category == first[2]) {
                    return prod
                }
            })

            this.$helpers.log(prod[0], 'prob');

            if (prod[0])
                this.showMobileComponentWithUrl(prod[0])
        }

    },

    beforeDestroy() {
        this.$store.commit("market/emptyCategoryArray");

    }
};
</script>

<style>
.mkt-link:hover{

text-decoration: underline;
transition: .9s;
}
.fa-angle-down {
    position: absolute;
    display: none;
    color: #000;
    top: 2px;
    font-size: 38px;
    z-index: -5;
    text-align: center;
}
</style>
