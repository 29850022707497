<template>
<div>
    <categoryMarket />
</div>
</template>

<script>
import categoryMarket from '../components/dashboard/CategoryMarket.vue'
export default {
    components: {
        categoryMarket
    }
}
</script>

<style>
